import request from "@/utils/request";
import { getAreaList, getCountryList } from "@/api/common";
import i18n from '@/locales/i18n';
import store from "@/store"

const locale = i18n.global.locale;

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function timeFormat(timestamp) {
  if (!timestamp) {
    return timestamp;
  }

  var value = parseInt(timestamp) * 1000;
  var time = new Date(value);
  const utc = time.getTime() + time.getTimezoneOffset() * 60000;
  const iaDate = new Date(utc + 480 * 60000); // 480 minutes = 8 hours

  var year = iaDate.getFullYear();
  var month = iaDate.getMonth() + 1;
  var date = iaDate.getDate();
  var hour = iaDate.getHours();
  var minute = iaDate.getMinutes();
  var second = iaDate.getSeconds();
  month = month < 10 ? "0" + month : month;
  date = date < 10 ? "0" + date : date;
  hour = hour < 10 ? "0" + hour : hour;
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
}

/**
 * 检查是否手机号码
 * @param {*} mobile 
 * @returns {Boolean}
 */
export function isMobile(mobile) {
    
    if(locale.value == "zh-cn") {
      var reg = /^1[3-9]\d{9}$/;
      return reg.test(mobile);
    }else {
      // 英文
      if(mobile.length < 6) {
        return false;
      }else {
        return true;
      }
    }
    
}

/**
 * 检查是否邮箱
 * @param {*} email 
 * @returns {Boolean}
 */
export function isEmail(email) {
    var reg = /^[\w\+\-]+(\.[\w\+\-]+)*@[a-z\d\-]+(\.[a-z\d\-]+)*\.([a-z]{2,4})$/i;
    return reg.test(email);
}

/**
 *  判断密码：包含大写字母和小写字母、数字、特殊字符两组的8-12位组合
 * @param {*}  
 * @returns {}
 */
export function isPassword(password) {
  // var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
  // var reg = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@#%^&+=]){8,12}$/;
  // var reg = /^(?![0-9]+$)(?!a-zA-Z]+$)[0-9A-Za-z\\W]{8,12}$/;
  // return reg.test(password);
  var len = password.length;
  if(len > 0){
      if(len < 8 || len > 12){
        return false;
      }
      var level = 0;
      if(/[a-z]/g.test(password) && /[A-Z]/g.test(password)) level++;
      if(/\d/g.test(password)) level++;
      if(/\W_/g.test(password)) level++;
      if(level <= 1){
        return false;
      }
      return true;
  }
  return true;
}


/**
 *  滚动到指定位置
 * @param {*}  
 * @returns {}
 */
 export function scrollToTarget(obj) {
  const targetElement = document.querySelector(obj);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
}


/**
 *  获取证件类型-静态数据
 * @param {*}  
 * @returns {}
 */
export function getCertificatesType() {
  return [
    {
      "id": 1,
      "name": "身份证",
    },
    {
      "id": 2,
      "name": "护照",
    },
    {
      "id": 3,
      "name": "港澳居民来往内地通行证",
    },
    {
      "id": 4,
      "name": "台湾居民来往大陆通行证",
    },
    {
      "id": 5,
      "name": "港澳台居民居住证",
    },
  ]
}


/**
 *  身份类型
 * @param {*}  
 * @returns {}
 */
export function visitorTypeText(id) {
  switch (id) {
    case 1:
      return {
        name_cn: "专业观众",
        name_en: "Visitor",
      };
      
    case 2:
      return {
        name_cn: "媒体观众",
        name_en: "Media",
      };

    case 3:
      return {
        name_cn: "特邀买家",
        name_en: "Match Me Buyers",
      };

    case 4:
      return {
        name_cn: "会议观众",
        name_en: "Meeting Visitor",
      };

    case 5:
      return {
        name_cn: "VIP观众",
        name_en: "VIP",
      };

    case 6:
      return {
        name_cn: "组团观众",
        name_en: "Visitor Delegation",
      };
    
    default: 
      return {
        name_cn: "",
        name_en: "",
      };
  }
}

/**
 *  身份类型
 * @param {*}  
 * @returns {}
 */
 export function payMethod(password) {
  switch (password) {
    case "wxpay":
      return "微信";
      
    case "alipay":
      return "支付宝";

    case "bank":
      return "线下付款";

    case "coupon":
      return "优惠券支付";

    case "free":
      return "免费门票";

    case "admin":
      return "管理员操作";

    case "give":
      return "主办方赠票";
    
    default: 
      return "";
    }
 }

/**
 * 
 * @param {*} url 
 */
export function imgUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject('请传入url内容')
    }
    if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
      // 图片地址
      const image = new Image()
      // 设置跨域问题
      image.setAttribute('crossOrigin', 'anonymous')
      // 图片地址
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = image.width
        canvas.height = image.height
        ctx.drawImage(image, 0, 0, image.width, image.height)
        // 获取图片后缀
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
        // 转base64
        const dataUrl = canvas.toDataURL(`image/${ext}`)
        resolve(dataUrl || '')
      }
    } else {
      // 非图片地址
      reject('非(png/jpe?g/gif/svg等)图片地址');
    }
  })
}



/**
 *  日期格式
 * @param {*}  
 * @returns {}
 */
export function formatTime(timestamp,format) {  
  if(!timestamp) {
    return ""
  }
  var formateArr  = ['Y','M','D','h','m','s'];  
  var returnArr   = [];  
  format = format || "Y-M-D";
  
  var date = new Date(timestamp * 1000);  
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const iaDate = new Date(utc + 480 * 60000); // 480 minutes = 8 hours

  returnArr.push(iaDate.getFullYear());  
  returnArr.push(formatNumber(iaDate.getMonth() + 1));  
  returnArr.push(formatNumber(iaDate.getDate()));  
  
  returnArr.push(formatNumber(iaDate.getHours()));  
  returnArr.push(formatNumber(iaDate.getMinutes()));  
  returnArr.push(formatNumber(iaDate.getSeconds()));  
  console.log("returnArr",returnArr);
  for (var i in returnArr)  
  {  
    format = format.replace(formateArr[i], returnArr[i]);  
  }  
  return format;  
} 

//数据转化  
function formatNumber(n) {  
  n = n.toString()  
  return n[1] ? n : '0' + n  
} 

/**
 * 两个日期合并
 */
export function rangeFormatTime(start, end, format) {
  console.log("rangeFormatTime-start",format.length);
  var start_date = new Date(start * 1000);  
  var end_date = new Date(end * 1000);  

  const start_utc = start_date.getTime() + start_date.getTimezoneOffset() * 60000;
  const ia_start_date = new Date(start_utc + 480 * 60000); // 480 minutes = 8 hours

  const end_utc = end_date.getTime() + end_date.getTimezoneOffset() * 60000;
  const ia_end_date = new Date(end_utc + 480 * 60000); // 480 minutes = 8 hours

  if(ia_start_date.getFullYear() != ia_end_date.getFullYear()) {
    // 不在同一年份
    return `${formatTime(start,format)} - ${formatTime(end,format)}`
  }
  if((ia_start_date.getMonth() + 1) != (ia_end_date.getMonth() + 1)) {
    // 不在同个月份
    var monthFormat = format.slice(format.indexOf('M'));
    return `${formatTime(start,format)}-${formatTime(end,monthFormat)}`
  }

  if(ia_start_date.getDay() != ia_end_date.getDay()) {
    // 不在同一日
    var dayFormat = format.slice(format.indexOf('D'));
    console.log("rangeFormatTime:",dayFormat,dayFormat.length, format,format.length);
    return `${formatTime(start,format)}-${formatTime(end,dayFormat)}`
  }
  
  var timeFormat = format.slice(format.indexOf(' h'));
  return `${formatTime(start,format)}-${formatTime(end,timeFormat)}`
}


/**
 * 获取当届参展的展会区域
 */
export function getExpoAreaList() {
  return new Promise((resolve, reject) => {
    getAreaList().then((res)=>{
      resolve(res)
    }).catch((err)=>{
      reject(err)
    })
  })
}

/**
 * 获取展会简称
 */
export function getExpoName(d) {
  const id = Number(d);
  switch (id) {
      case 1:
          return {
            label: "shanghai",
            name_cn: "上海展",
            name_en: "Shanghai"
          };
      case 2:
        return {
          label: "shenzhen",
          name_cn: "深圳展",
          name_en: "Shenzhen"
        };
      default: 
          return {
            label: "",
            name_cn: "",
            name_en: ""
          };
  }
}


/**
 * 补齐主站域名
 * url
 */
export function addHomeDomain(url, expo_id, lang) {
  if(url.indexOf("http") == -1) {
    return `${store.state.home_domain}${url}`
  }else {
    return url;
  }
}

/**
 * 补齐主站域名
 * url
 */
export function addOnlineDomain(url, expo_id, lang) {
  if(url.indexOf("http") == -1) {
    return `${store.state.online_domain}${url}`
  }else {
    return url;
  }
}


/**
 * 获取热门主题线路
 * url
 */
export function getRecommandedTrip(expo_area_id) {
  if(expo_area_id == 2) {
    return [
      {
        id: 4,
        title_cn: "健康食品",
        title_en: "Health Food"
      },
      {
        id: 7,
        title_cn: "饮品",
        title_en: "Beverage"
      },
      {
        id: 8,
        title_cn: "首次入华南产品",
        title_en: "First-time to South China"
      }
    ]
  }
  return [
    // {
    //   id: 1,
    //   title_cn: "预制菜",
    //   title_en: "Ready to cook, Ready to heat, Ready to eat",
    // },
    // {
    //   id: 2,
    //   title_cn: "功能性饮料",
    //   title_en: "Storable Drinks"
    // },
    // {
    //   id: 3,
    //   title_cn: "新式茶饮",
    //   title_en: "Milk Tea/Bubble Tea"
    // },
    {
      id: 4,
      title_cn: "健康食品",
      title_en: "Health Food"
    },
    {
      id: 5,
      title_cn: "儿童食品",
      title_en: "Baby Food"
    },
    {
      id: 6,
      title_cn: "酒",
      title_en: "Alcohol"  
    },
    
  ]
}
