import request from "@/utils/request";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { getExpoAreaList } from "@/utils/common";
import router from "@/router/index";
import { getRules } from "@/api/auth";
import { getVerifyInfo } from "@/api/common";
import i18n from "@/locales/i18n";
import Cookies from "js-cookie";

import Layout from "@/views/Layout/Index.vue";

let currLang = "/";
let currentLocale = "cn";
if (window.location.pathname.indexOf("/en") == 0) {
  currLang = "/en";
  currentLocale = "en";
  i18n.global.locale.value = "en";
}
if(window.location.hostname == "exhibitor.sialchina.com") {
  currentLocale = "en";
  i18n.global.locale.value = "en";
}

const state = {
  roles: Cookies.get("roles"), // 1：普通， 2：往届， 3：当届， 5：当届国际展商
  token: getToken(),
  routers: [],
  expo_area_list: [],//当届参展的展会区域
  verifyInfo: [],// 会刊展品完成度凭证
  show_matchmaking: Cookies.get("show_matchmaking") || false,
};
const mutations = {
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROUTERS: (state, data) => {
    state.routers = data;
  },
  SET_AREA_EXPO_LIST: (state, list) => {
      state.expo_area_list = list;
  },
  SET_VERIFYINFO: (state, data) => {
      state.verifyInfo = data;
  },
  SET_MATCHMAKING: (state, data) => {
    state.show_matchmaking = data;
  }
};

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { is_frence } = userInfo;
    console.log("login-is_frence", is_frence);
    if(!is_frence) {
      return new Promise((resolve, reject) => {
        request({
          url: "/do_login",
          method: "post",
          data: userInfo,
        })
          .then(async(response) => {

            const { data } = response;

            // 存放令牌状态
            commit("SET_TOKEN", data.jwt);
            commit("SET_ROLES", data.payload.roles);
            Cookies.set("roles", data.payload.roles);
            Cookies.set("account", data.payload.account);
            Cookies.set("token_login", data.payload.token_login);

            setToken(data.jwt);

            await getVerifyInfo({app_lang: i18n.global.locale.value}).then((res)=>{
              const { data } = res;
              commit("SET_VERIFYINFO", data);
            }).catch(()=>{})

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }else {
      // 法国单点登录
      const { inscription_id } = userInfo;
      return new Promise((resolve, reject) => {
        request({
          url: "/french_ssoLogin",
          method: "post",
          data: {inscription_id},
        })
          .then((response) => {
            const { data } = response;
            // 存放令牌状态
            commit("SET_TOKEN", data.jwt);
            commit("SET_ROLES", data.payload.roles);
            Cookies.set("roles", data.payload.roles)
            Cookies.set("account", data.payload.account);
            Cookies.set("token_login", data.payload.token_login);
  
            setToken(data.jwt);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    
  },

  // 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/logout",
        method: "GET",
        data: "",
      })
        .then(() => {
          // 清除令牌状态
          commit("SET_TOKEN", "");
          commit("SET_ROUTERS", []);

          Cookies.remove("roles")
          Cookies.remove("account")
          Cookies.remove("token_login")
          Cookies.remove("invitevst_pages")
          Cookies.remove("invitevst_index")
          removeToken();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取动态路由
  generateRoutes({ dispatch, commit, state }) {
    return new Promise((resolve, reject) => {
      getRules()
        .then(async (res) => {
          const { data } = res;
          console.log("generateRoutes",data);
          // 判断商贸配对是否关闭
          const showMatch = handleShowRuleBtn(data, 13);
          commit("SET_MATCHMAKING", showMatch);
          Cookies.set("show_matchmaking", showMatch);
           // 获取展商参展的当届展区
          await getExpoAreaList()
          .then((res) => {
            const { data } = res;
            commit("SET_AREA_EXPO_LIST", data);
          })
          .catch(() => {});

          const filterName = [];
          let area_id = [];
          state.expo_area_list.map((o)=>{
            area_id.push(o.id);
          })
          if(!area_id.includes(1)) {filterName.push("上海创新大赛")}
          if(!area_id.includes(2)) {filterName.push("深圳创新大赛")}

          if(data.length == 0) {
            data.push({
              "id": 1,
              "title": "首页",
              "page_url": "Index/index",
              "uri": "Index/index",
              "condition": "",
              "redirect": "",
              "pid": 0,
              "is_menu": 1,
              "icon": "fa fa-home",
              "remark": "",
              "sort": 0,
              "status": 1,
              "delete_time": 0,
              "spacer": "",
              "childlist": []
          })
          }
          
          
          let accessedRoutes = rulesToRouters(data, filterName);
          accessedRoutes = addExtraRouters(accessedRoutes)
          
          console.log("accessedRoutes",accessedRoutes);
          commit("SET_ROUTERS", accessedRoutes);
          dispatch("addRoutes", accessedRoutes);
          resolve(accessedRoutes);
        })
        .catch((err) => {
          // showMsg.error(err.msg);
          reject();
        });
    });
  },
  addRoutes({ commit }, accessRoutes) {
    // 添加动态路由
    accessRoutes.forEach((route) => {
      // console.log("route", route);
      router.addRoute(route);
    });
  },
};

function rulesToRouters(data, filterName) {
  if (!data.length) {
    return [];
  }
  const routes = [];
  const filterUriEn = ["Badges/badgesEmbedded", "Sponsor/index", "Company/contacts"];
  const filterUriCn = ["Visa/index"];
  console.log("process.env.NODE_ENV",process.env.NODE_ENV);
  if(process.env.NODE_ENV == "productiontest") {
    filterUriCn.push("Badges/badgesEmbedded")
  }
  let filterNames = filterName;
  
  data.map((o, i) => {
    if((currentLocale == "en" && !filterUriEn.includes(o.uri) && !filterNames.includes(o.title)) || (currentLocale == "cn" && !filterUriCn.includes(o.uri)) && !filterNames.includes(o.title)) {
      let routeItem;
      var path;
      var uri = o.uri;
      if(uri.indexOf('http') != -1 && currentLocale == "en") {
        let url = new URL(uri);
        if(url.host == 'visitor.sialchina.cn') {
          uri = `https://visitor.sialchina.com${url.pathname}${url.search}`
        }
        if(url.host == 'www.sialchina.cn') {
          uri = `https://www.sialchina.com${url.pathname}${url.search}`
        }
      }
      if(o.condition) {
        path = o.uri ? `${o.uri}?${o.condition}` : currLang
      }else {
        path = uri || currLang
      }
      if (o.pid == 0) {
        
        if(!o.childlist || o.childlist.length == 0) {
          routeItem = {
            path: currLang,
            component: Layout,
            name: i18n.global.t(`meta.${o.title}`),
            meta: {
              title: i18n.global.t(`meta.${o.title}`),
              icon: o.icon,
            },
            children: [{
              path: path,
              component: o.page_url
                        ? () => import(`@/views/${o.page_url}.vue`)
                        : "",
              meta: { 
                  title: i18n.global.t(`meta.${o.title}`),
                  icon: o.icon,
              },
              },]
          };
        }else {
          routeItem = {
            path: path,
            component: Layout,
            name: i18n.global.t(`meta.${o.title}`),
            meta: {
              title: i18n.global.t(`meta.${o.title}`),
              icon: o.icon,
            },
            children: []
          };
        }
      } else {
        routeItem = {
          path: path,
          component: o.page_url
            ? () => import(`@/views/${o.page_url}.vue`)
            : "",
          name: i18n.global.t(`meta.${o.title}`),
          meta: {
            title: i18n.global.t(`meta.${o.title}`),
            icon: o.icon,
          },
        };
      }
      if (o.childlist && o.childlist.length > 0) {
        routeItem.children.push(...rulesToRouters(o.childlist, filterName));
      }
      routes.push(routeItem);
    }
  });
  return routes;
}

function addExtraRouters(data) {
  const host = ["exhibitor.sialchina.cn", "exhibitor.sialchina.com"];
  let accessedRoutes = data
  if(!host.includes(window.location.hostname) && window.location.pathname.indexOf("/en") == 0) {
    accessedRoutes.push({
      path: "/en/:pathMatch(.*)*",
      redirect: "/en/Index/index",
    })
  }
  accessedRoutes.push({
    path: currLang,
    children: [
      {
        path: currentLocale == "en" ? "https://www.sialchina.com/events/steak.html" : "https://www.sialchina.cn/events/steak.html",
        meta: { title: i18n.global.t("meta.臻牛奖"),icon: "fa fa-trophy",},
      },
    ]
  })
   accessedRoutes.push({
    path: currLang,
    children: [
      {
        path: currentLocale == "en" ? `https://catalogue.sialchina.cn/en/siglelogin?account=${Cookies.get("account")}&token_login=${Cookies.get("token_login")}` : `https://catalogue.sialchina.cn/siglelogin?account=${Cookies.get("account")}&token_login=${Cookies.get("token_login")}`,
        meta: { title: i18n.global.t("meta.电子会刊"),icon: "fa fa-book",},
      },
    ]
  })
  accessedRoutes.push({
    path: "/:pathMatch(.*)*",
    redirect: "/Index/index",
  })
  return accessedRoutes;
}

function handleShowRuleBtn(data, id) {
  let result = false;
  data.map((o)=>{
    if(o.id == id) {
      result = true;
    }
  })
  return result;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
